import { Controller } from "@hotwired/stimulus";
import { cancelOpenModalEvent, isInputFilled, isStringTrue, toggleClass } from "../../utils/misc";
import { roundToTwoDecimals } from "../../utils/number_utils";
import { notifyError } from "../../components/flash_alert";

export default class extends Controller {
  static targets = [
    "percentTypeRadioButton",
    "instalmentGrossAmount",
    "instalmentCurrencySymbol",
    "instalmentComputedAmountText",
    "instalmentComputedAmount",
    "instalmentType",
    "instalmentNote",
    "quoteId",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this.quote = this._formattedQuote(event);
      this._initModal(event);
    });
  }

  _initModal(event) {
    if (isStringTrue(this.quote.hasInstalmentInvoices)) {
      notifyError("Un acompte a déjà été facturé pour ce devis.");
      cancelOpenModalEvent(event);
      return;
    }

    this._resetInputs();
    this._setQuoteId();
    this._setCurrencySymbol();
  }

  computeInstalmentAmount() {
    const quoteGrossTotal = isStringTrue(this.quote.foreignCurrency) ? this.quote.fxGrossTotal : this.quote.grossTotal;

    this.instalmentComputedAmountTarget.value = this._instalmentComputedAmount(quoteGrossTotal);

    toggleClass(this.instalmentComputedAmountTextTarget, "hidden", this._isPercentType());
  }

  _instalmentComputedAmount(quoteAmount) {
    if (!isInputFilled(this.instalmentGrossAmountTarget)) {
      return 0;
    }

    const instalmentAmount = this.instalmentGrossAmountTarget.value;

    if (this._isPercentType()) {
      return this._percentValue(quoteAmount, instalmentAmount);
    }

    return instalmentAmount;
  }

  _isPercentType() {
    return this.percentTypeRadioButtonTarget.checked === true;
  }

  _percentValue(quoteAmount, inputValue) {
    return roundToTwoDecimals(quoteAmount * (parseFloat(inputValue) / 100));
  }

  _resetInputs() {
    this.percentTypeRadioButtonTarget.checked = true;

    this.instalmentComputedAmountTarget.value = "0";

    [this.instalmentGrossAmountTarget, this.instalmentNoteTarget].forEach((target) => (target.value = ""));
  }

  _setQuoteId() {
    this.quoteIdTarget.value = this.quote.id;
  }

  _setCurrencySymbol() {
    this.instalmentCurrencySymbolTargets.forEach((target) => {
      target.innerText = this.quote.currencySymbol;
    });
  }

  _formattedQuote = (event) => {
    const { id, gross_total, fx_gross_total, currency_symbol, foreign_currency, has_instalment_invoices } = JSON.parse(
      event.relatedTarget.dataset.details
    );

    return {
      id: id,
      grossTotal: gross_total,
      fxGrossTotal: fx_gross_total,
      currencySymbol: currency_symbol,
      foreignCurrency: foreign_currency,
      hasInstalmentInvoices: has_instalment_invoices,
    };
  };
}
