import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { hideElements, showElements, toggleClass } from "../../utils/misc";

const STEPS = {
  INITIAL: 0,
  BUSINESS_CUSTOMER_SEARCH: 2,
  CUSTOMER_MAIN_INFORMATION: 3,
};
const LOCAL_COUNTRY_LOCATION = "1";

export default class extends Controller {
  static targets = [
    "individualCustomer",
    "country",
    "sirenField",
    "vatNumberField",
    "recoveryCostsCompensationWrapper",
    "step",
    "nextStepBtn",
    "previousStepBtn",
  ];
  connect() {
    this._groupSectionFields();

    this.stepId = STEPS.INITIAL;

    const { modalId } = this.element.dataset;
    window.$(`#${modalId}`).on("hide.bs.modal", () => {
      this._reinitializeForm();
    });
  }

  get form() {
    return this.element;
  }

  _reinitializeForm() {
    hideElements([this.previousStepBtnTarget]);
    showElements([this.nextStepBtnTarget]);

    this._changeStep(this.stepId, STEPS.INITIAL);

    this.form.reset();
  }

  previousStep() {
    let nextStep = this.stepId - 1;

    if (this.stepId === STEPS.CUSTOMER_MAIN_INFORMATION && this._isIndividualCustomer()) {
      nextStep = STEPS.INITIAL;
    }

    this._changeStep(this.stepId, nextStep);
  }

  nextStep() {
    $(this.form)
      .parsley()
      .whenValidate({ group: `group-${this.stepId}` })
      .done(() => {
        let nextStep = this.stepId + 1;

        if (this.stepId === STEPS.INITIAL && this._isIndividualCustomer()) {
          nextStep = STEPS.CUSTOMER_MAIN_INFORMATION;
        }

        this._changeStep(this.stepId, nextStep);
      });
  }

  onLocationChange(event) {
    switch (event.target.value) {
      case "individual": {
        this.sirenFieldTarget.classList.add("hidden");
        this.vatNumberFieldTarget.classList.add("hidden");
        this.recoveryCostsCompensationWrapperTarget.classList.add("hidden");
        break;
      }
      case "business": {
        this.recoveryCostsCompensationWrapperTarget.classList.remove("hidden");
        break;
      }
      case "france": {
        this.sirenFieldTarget.classList.remove("hidden");
        this.vatNumberFieldTarget.classList.remove("hidden");
        break;
      }
      case "europe": {
        this.sirenFieldTarget.classList.add("hidden");
        this.vatNumberFieldTarget.classList.remove("hidden");
        break;
      }
      case "non_europe": {
        this.sirenFieldTarget.classList.add("hidden");
        this.vatNumberFieldTarget.classList.add("hidden");
        break;
      }
    }

    const location = event.target.dataset.location || LOCAL_COUNTRY_LOCATION;
    this._setNewCountryList(parseInt(location));

    this.nextStep();
  }

  _setNewCountryList(location) {
    const { countriesUrl } = this.countryTarget.dataset;

    $.ajax({
      url: countriesUrl,
      type: "get",
      data: { location: location },
      success: function () {},
    });
  }

  _isIndividualCustomer() {
    return this.individualCustomerTarget.checked === true;
  }

  skipSearch() {
    this._changeStep(this.stepId, STEPS.CUSTOMER_MAIN_INFORMATION);
  }

  _changeStep(currentStep, nextStep) {
    this._handleNextStepButtonDisplay(nextStep);
    this._handlePreviousStepButtonDisplay(nextStep);

    this._findStep(currentStep).classList.add("hidden");
    this._findStep(nextStep).classList.remove("hidden");
    this.stepId = nextStep;
  }

  _findStep(stepId) {
    return this.stepTargets.find((target) => target.dataset.stepId === stepId.toString());
  }

  _groupSectionFields() {
    this.stepTargets.forEach((section, index) => {
      $(section).find(":input").attr("data-parsley-group", `group-${index}`);
    });
  }

  _handlePreviousStepButtonDisplay(stepId) {
    toggleClass(this.previousStepBtnTarget, "hidden", stepId !== STEPS.INITIAL);
  }

  _handleNextStepButtonDisplay(stepId) {
    toggleClass(
      this.nextStepBtnTarget,
      "hidden",
      [STEPS.BUSINESS_CUSTOMER_SEARCH, STEPS.CUSTOMER_MAIN_INFORMATION].includes(stepId)
    );
  }
}
